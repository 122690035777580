import React from 'react';
import classes from './Dashboard.module.css';
import DashNav from '../DashNav';
import DashBody from '../DashBody';

function Dashboard() {
	return (
		<div className={classes.main}>
			<aside className={classes.nav}>
				<DashNav />
			</aside>
			<main className={classes.dashbody}>
				<DashBody />
			</main>
		</div>
	);
}

export default Dashboard;
