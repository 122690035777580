import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../helpers/AuthContext';

const ProtectedRoute = ({ element: Component, role, ...rest }) => {
	const { authState } = useContext(AuthContext);

	if (!authState.status) {
		return <Navigate to='/signin' />;
	}

	return <Component {...rest} />;
};

export default ProtectedRoute;
