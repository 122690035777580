import React, { useState } from 'react';
import classes from './SellCar.module.css';
import heroImage from '../assets/white.jpg';

const API_URL = `${window.location.protocol}//db.africagoldrefineries.com`;

function SellCar() {
	const [formData, setFormData] = useState({
		fullName: '',
		email: '',
		phone: '',
		address: '',
		make: '',
		model: '',
		year: '',
		price: '',
		images: [],
		description: '',
		mileage: '',
		condition: '',
	});
	const [imagePreviews, setImagePreviews] = useState([]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleImageChange = (e) => {
		const files = Array.from(e.target.files);
		setFormData((prevFormData) => ({
			...prevFormData,
			images: files,
		}));

		const filePreviews = files.map((file) => URL.createObjectURL(file));
		setImagePreviews((prevPreviews) => [...prevPreviews, ...filePreviews]);
	};

	const handleImageDelete = (index) => {
		const newImages = [...formData.images];
		const newImagePreviews = [...imagePreviews];

		newImages.splice(index, 1);
		newImagePreviews.splice(index, 1);

		setFormData((prevFormData) => ({
			...prevFormData,
			images: newImages,
		}));
		setImagePreviews(newImagePreviews);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formDataToSend = new FormData();
		for (const key in formData) {
			if (key === 'images') {
				formData.images.forEach((file, i) => {
					formDataToSend.append(`images[${i}]`, file);
				});
			} else {
				formDataToSend.append(key, formData[key]);
			}
		}

		try {
			const response = await fetch(`${API_URL}/api/send-sell-email`, {
				method: 'POST',
				body: formDataToSend,
			});
			const result = await response.text();
			if (response.ok) {
				alert('Email sent successfully!');
				setFormData({
					fullName: '',
					email: '',
					phone: '',
					address: '',
					make: '',
					model: '',
					year: '',
					price: '',
					images: [],
					description: '',
					mileage: '',
					condition: '',
				});
				setImagePreviews([]);
			} else {
				alert('Failed to send email: ' + result);
			}
		} catch (error) {
			alert('Error: ' + error.message);
		}
	};

	return (
		<div className={classes.sellcar}>
			<div className={classes.wrapper}>
				<div className={classes.intro}>
					<h1>Sell Your Car with Ease</h1>
					<div className={classes.image}>
						<img
							src={heroImage}
							alt='Hero'
						/>
					</div>
					<h2>Why Sell Your Car with Kenjap Motors LTD?</h2>
					<p>
						At Kenjap Motors LTD, we make selling your car a hassle-free
						experience. Whether you’re looking to upgrade to a new vehicle or
						simply want to cash out, we provide a streamlined process that
						ensures you get the best value for your car. Here’s why you should
						choose us:
					</p>
					<ul>
						<li>
							<b>Competitive Offers: </b>Get a fair and competitive offer for
							your car.
						</li>
						<li>
							<b>Quick and Easy Process: </b>From valuation to payment, our
							process is designed to be fast and straightforward.
						</li>
						<li>
							<b>No Hidden Fees: </b>Transparency is our priority; we don’t
							charge any hidden fees or commissions.
						</li>
						<li>
							<b>Trusted Service: </b>Join thousands of satisfied customers who
							have sold their cars with us.
						</li>
					</ul>
					<h2 className={classes.space}>How It Works</h2>
					<ol>
						<li>
							<b>Get a Free Valuation: </b>Enter your car’s details in our
							valuation tool on the side and get an estimate.
						</li>
						<li>
							<b>Book an Appointment: </b>Schedule a convenient time for an
							in-person inspection at one of our locations.
						</li>
						<li>
							<b>Car Inspection: </b>Our experts will conduct a thorough
							inspection of your car.
						</li>
						<li>
							<b>Receive an Offer: </b>Based on the inspection, we’ll provide
							you with a final offer.
						</li>
						<li>
							<b>Get Paid: </b>Once you accept the offer, we handle all the
							paperwork and your car gets listed. Full payment is made after the
							car has been sold.
						</li>
					</ol>
					<h2 className={classes.space}>What You Need to Bring</h2>
					<p>
						To ensure a smooth transaction, please bring the following
						documents:
					</p>
					<ul>
						<li>
							<b>Log Book: </b>Proof of ownership.
						</li>
						<li>
							<b>Identification: </b>A government-issued ID.
						</li>
						<li>
							<b>Service Records: </b>Any available service and maintenance
							records.
						</li>
						<li>
							<b>Keys and Remotes: </b>All keys, remotes, and owner’s manuals.
						</li>
					</ul>
					<h2 className={classes.space}>FAQs</h2>
					<ol>
						<li>
							How long does the entire process take?
							<ul>
								<li>
									Typically, the process from inspection to payment can be
									completed within 24 hours.
								</li>
							</ul>
						</li>
						<li>
							Can I sell a car that is not fully paid off?
							<ul>
								<li>
									Yes, you can. We will help you settle the outstanding loan
									amount as part of the sale process.
								</li>
							</ul>
						</li>
						<li>
							What if my car is not running or damaged?
							<ul>
								<li>
									We buy cars in all conditions. Our experts will evaluate your
									car and provide a fair offer based on its condition.
								</li>
							</ul>
						</li>
					</ol>
				</div>

				<form
					className={classes.form}
					onSubmit={handleSubmit}>
					<h2>Fill form to sell your car</h2>
					<label className={classes.label}>
						<p>Full Name: </p>
						<input
							type='text'
							name='fullName'
							value={formData.fullName}
							onChange={handleChange}
							required
						/>
					</label>
					<label className={classes.label}>
						<p>Email: </p>
						<input
							type='email'
							name='email'
							value={formData.email}
							onChange={handleChange}
							required
						/>
					</label>
					<label className={classes.label}>
						<p>Phone: </p>
						<input
							type='tel'
							name='phone'
							value={formData.phone}
							onChange={handleChange}
							required
						/>
					</label>
					<label className={classes.label}>
						<p>Address: </p>
						<input
							type='text'
							name='address'
							value={formData.address}
							onChange={handleChange}
							required
						/>
					</label>
					<label className={classes.label}>
						<p>Make: </p>
						<input
							type='text'
							name='make'
							value={formData.make}
							onChange={handleChange}
							required
						/>
					</label>
					<label className={classes.label}>
						<p>Model: </p>
						<input
							type='text'
							name='model'
							value={formData.model}
							onChange={handleChange}
							required
						/>
					</label>
					<label className={classes.label}>
						<p>Year: </p>
						<input
							type='number'
							name='year'
							value={formData.year}
							onChange={handleChange}
							required
							min='1886'
							max={new Date().getFullYear()}
						/>
					</label>
					<label className={classes.label}>
						<p>Mileage: </p>
						<input
							type='number'
							name='mileage'
							value={formData.mileage}
							onChange={handleChange}
							required
							min='0'
						/>
					</label>
					<label className={classes.label}>
						<p>Price: </p>
						<input
							type='number'
							name='price'
							value={formData.price}
							onChange={handleChange}
							required
							min='0'
						/>
					</label>
					<label className={classes.textarea}>
						<p>Description: </p>
						<textarea
							name='description'
							value={formData.description}
							onChange={handleChange}
							required
						/>
					</label>
					<label className={classes.condition}>
						<p>Condition: </p>
						<select
							name='condition'
							className={classes.select}
							value={formData.condition}
							onChange={handleChange}
							required>
							<option value=''>Select Condition</option>
							<option value='New'>New</option>
							<option value='Used'>Used</option>
							<option value='Certified Pre-Owned'>Certified Pre-Owned</option>
						</select>
					</label>
					<label className={classes.imageSelect}>
						Images:
						<input
							type='file'
							name='images'
							className={classes.files}
							onChange={handleImageChange}
							multiple
							required
						/>
					</label>
					<div className={classes.imagePreviews}>
						{imagePreviews.map((preview, index) => (
							<div
								key={index}
								className={classes.imagePreview}>
								<img
									src={preview}
									alt={`Preview ${index}`}
								/>
								<button
									className={classes.delete}
									type='button'
									onClick={() => handleImageDelete(index)}>
									X
								</button>
							</div>
						))}
					</div>
					<button type='submit'>Submit</button>
				</form>
			</div>
		</div>
	);
}

export default SellCar;
