import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './DashNav.module.css';

function DashNav() {
	const [vehiclesMenuOpen, setVehiclesMenuOpen] = useState(false);

	const toggleVehiclesMenu = () => setVehiclesMenuOpen(!vehiclesMenuOpen);

	return (
		<nav className={classes.nav}>
			<h2>Dashboard</h2>
			<ul>
				<li>
					<NavLink
						to='overview'
						className={classes.overview}>
						Overview
					</NavLink>
				</li>
				<li>
					<p onClick={toggleVehiclesMenu}>Vehicles</p>
					{vehiclesMenuOpen && (
						<ul>
							<li>
								<NavLink
									to='vehicles'
									className={classes.vehview}>
									View Vehicles
								</NavLink>
							</li>
							<li>
								<NavLink
									to='vehicles/add'
									className={classes.vehadd}>
									Add Vehicle
								</NavLink>
							</li>
						</ul>
					)}
				</li>
				<li>
					<NavLink
						to='profile'
						className={classes.prof}>
						Profile
					</NavLink>
				</li>
			</ul>
		</nav>
	);
}

export default DashNav;
