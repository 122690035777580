import React from 'react';
import classes from './Policy.module.css';

function Policy() {
	return (
		<div className={classes.main}>
			<div className={classes.wrapper}>
				<h1>Privacy Policy</h1>
				<p>
					Kenjap Motors Ltd ("we," "our," "us") is committed to protecting and
					respecting your privacy. This Privacy Policy explains how we collect,
					use, disclose, and safeguard your information when you visit our
					website.
				</p>
				<h2>1. Information We Collect</h2>
				<p>
					We may collect information about you in a variety of ways, including:
					<ul>
						<li>
							<strong>Personal Data:</strong> Personally identifiable
							information, such as your name, shipping address, email address,
							and telephone number, and demographic information, such as your
							age, gender, hometown, and interests, that you voluntarily give to
							us when you register with the site or when you choose to
							participate in various activities related to the site.
						</li>
						<li>
							<strong>Derivative Data:</strong> Information our servers
							automatically collect when you access the site, such as the pages
							you have viewed on our website.
						</li>
						<li>
							<strong>Financial Data:</strong> Financial information, such as
							data related to your payment method (e.g., valid credit card
							number, card brand, expiration date) that we may collect when you
							purchase, order, return, exchange, or request information about
							our services from the site.
						</li>
					</ul>
				</p>
				<h2>2. Use of Your Information</h2>
				<p>
					Having accurate information about you permits us to provide you with a
					smooth, efficient, and customized experience. Specifically, we may use
					information collected about you via the site to:
					<ul>
						<li>Create and manage your account.</li>
						<li>
							Process your transactions and send you related information,
							including purchase confirmations and invoices.
						</li>
						<li>Administer promotions, surveys, and other site features.</li>
						<li>
							Respond to your comments, questions, and provide customer service.
						</li>
						<li>Improve our website to better serve you.</li>
						<li>Send you marketing and promotional communications.</li>
					</ul>
				</p>
				<h2>3. Disclosure of Your Information</h2>
				<p>
					We may share information we have collected about you in certain
					situations. Your information may be disclosed as follows:
					<ul>
						<li>
							<strong>By Law or to Protect Rights:</strong> If we believe the
							release of information about you is necessary to respond to legal
							process, to investigate or remedy potential violations of our
							policies, or to protect the rights, property, and safety of
							others, we may share your information as permitted or required by
							any applicable law, rule, or regulation.
						</li>
						<li>
							<strong>Business Transfers:</strong> We may share or transfer your
							information in connection with, or during negotiations of, any
							merger, sale of company assets, financing, or acquisition of all
							or a portion of our business to another company.
						</li>
						<li>
							<strong>Third-Party Service Providers:</strong> We may share your
							information with third parties that perform services for us or on
							our behalf, including payment processing, data analysis, email
							delivery, hosting services, customer service, and marketing
							assistance.
						</li>
					</ul>
				</p>
				<h2>4. Security of Your Information</h2>
				<p>
					We use administrative, technical, and physical security measures to
					help protect your personal information. While we have taken reasonable
					steps to secure the personal information you provide to us, please be
					aware that despite our efforts, no security measures are perfect or
					impenetrable, and no method of data transmission can be guaranteed
					against any interception or other type of misuse.
				</p>
				<h2>5. Policy for Children</h2>
				<p>
					We do not knowingly solicit information from or market to children
					under the age of 13. If we learn that we have collected personal
					information from a child under age 13 without verification of parental
					consent, we will delete that information as quickly as possible.
				</p>
				<h2>6. Changes to This Privacy Policy</h2>
				<p>
					We may update this Privacy Policy from time to time in order to
					reflect, for example, changes to our practices or for other
					operational, legal, or regulatory reasons. We will notify you of any
					changes by posting the new Privacy Policy on this page.
				</p>
				<h2>7. Contact Us</h2>
				<p>
					If you have any questions or concerns about this Privacy Policy,
					please contact us at:
					<br />
					Kenjap Motors Ltd
					<br />
					West Road, Adjacent to The Ole-Ken Hotel
					<br />
					Next to Mt. Kenya Uni. Nakuru Campus
					<br />
					Phone: +254 722 774 846
					<br />
					Email: info@kenjapmotors.com
				</p>
			</div>
		</div>
	);
}

export default Policy;
