import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import classes from './Overview.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';	

const API_URL = `${window.location.protocol}//db.africagoldrefineries.com`;

function Overview() {
	const [totalVehicles, setTotalVehicles] = useState(0);
	const [newCarData, setNewCarData] = useState([]);
	const [usedCarData, setUsedCarData] = useState([]);
	const [recentCars, setRecentCars] = useState([]);
	const [date, setDate] = useState(new Date());

	useEffect(() => {
		const fetchTotalVehicles = async () => {
			try {
				const token = localStorage.getItem('token');
				const response = await axios.get(`${API_URL}/api/vehicles`, {
					headers: { Authorization: `Bearer ${token}` },
				});
				const totalVehiclesCount = response.data.length;
				setTotalVehicles(totalVehiclesCount);

				const sortedVehicles = response.data.sort((a, b) => {
					return new Date(b.dateAdded) - new Date(a.dateAdded);
				});
				const recentCarsData = sortedVehicles.slice(0, 3);
				setRecentCars(recentCarsData);
			} catch (error) {
				console.error('Error fetching total vehicles:', error);
			}
		};

		fetchTotalVehicles();
	}, []);

	useEffect(() => {
		const fetchNewCars = async () => {
			try {
				const response = await axios.get(`${API_URL}/api/new-cars`);
				const initialNewCars = response.data;
				setNewCarData(initialNewCars);
			} catch (error) {
				console.error('Error fetching new cars:', error);
			}
		};

		fetchNewCars();
	}, []);

	useEffect(() => {
		const fetchUsedCars = async () => {
			try {
				const response = await axios.get(`${API_URL}/api/used-cars`);
				const initialUsedCars = response.data;
				setUsedCarData(initialUsedCars);
			} catch (error) {
				console.error('Error fetching used cars:', error);
			}
		};

		fetchUsedCars();
	}, []);

	return (
		<div className={classes.main}>
			<h1>Overview</h1>
			<div className={classes.container}>
				<div className={classes.wrapperClass}>
					<div className={classes.overviewSection}>
						<div className={classes.overviewCard}>
							<FontAwesomeIcon
								className={classes.car}
								icon={faCar}
							/>
							<div className={classes.intro}>
								<h2>{totalVehicles}</h2>
								<p>Total Vehicles</p>
							</div>
						</div>
						<div className={classes.overviewCard}>
							<FontAwesomeIcon
								className={classes.carNew}
								icon={faCar}
							/>
							<div className={classes.intro}>
								<h2>{newCarData.length}</h2>
								<p>New Vehicles</p>
							</div>
						</div>
						<div className={classes.overviewCard}>
							<FontAwesomeIcon
								className={classes.carUsed}
								icon={faCar}
							/>
							<div className={classes.intro}>
								<h2>{usedCarData.length}</h2>
								<p>Used Vehicles</p>
							</div>
						</div>
					</div>

					<div className={classes.recentActivities}>
						<h4>Recent Activities</h4>
						<div className={classes.recentCards}>
							{recentCars.map((car, index) => (
								<div
									className={classes.recentCard}
									key={index}>
									<div className={classes.image}>
										{car.images && car.images.length > 0 && (
											<img
												src={`${API_URL}/images/cars/${car.images[0].imageName}`}
												alt={`${car.make} ${car.model}`}
											/>
										)}
									</div>
									<div className={classes.details}>
										<h4>{`${car.year} ${car.make} ${car.model}`}</h4>
										<p>
											Mileage: <b>{car.mileage} </b>km
										</p>
										<p>
											Date Added: {moment(car.dateAdded).format('MMM DD, YYYY')}
										</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className={classes.calender}>
					<Calendar
						onChange={setDate}
						value={date}
						className={classes.theCalender}
						locale='en-US'
					/>
				</div>
			</div>
		</div>
	);
}

export default Overview;
