import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './CarDetail.module.css';

const API_URL = `${window.location.protocol}//db.africagoldrefineries.com`;

function CarDetail() {
	const { id } = useParams();
	const [car, setCar] = useState(null);
	const [editing, setEditing] = useState(false);
	const [formData, setFormData] = useState({
		year: '',
		make: '',
		model: '',
		mileage: '',
		engineCapacity: '',
		price: '',
		exteriorColor: '',
		interiorColor: '',
		drivetrain: '',
		fuelType: '',
		transmission: '',
		stockType: '',
		description: '',
	});
	const [formError, setFormError] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchCar = async () => {
			try {
				const response = await axios.get(`${API_URL}/api/vehicles/${id}`);
				setCar(response.data);
				setFormData({
					year: response.data.year,
					make: response.data.make,
					model: response.data.model,
					mileage: response.data.mileage,
					engineCap: response.data.engineCap,
					price: response.data.price,
					extColor: response.data.extColor,
					intColor: response.data.intColor,
					driveType: response.data.driveType,
					fuel: response.data.fuel,
					transmission: response.data.transmission,
					stockType: response.data.stockType,
					description: response.data.description,
				});
			} catch (error) {
				console.error('Error fetching car details:', error);
				setError(error);
			}
		};

		fetchCar();
	}, [id]);

	const handleDeleteImage = async (imageId) => {
		try {
			await axios.delete(`${API_URL}/api/vehicleImages/${imageId}`);
			const response = await axios.get(`${API_URL}/api/vehicles/${id}`);
			setCar(response.data);
		} catch (error) {
			console.error('Error deleting image:', error);
		}
	};

	const handleEdit = () => {
		setEditing(true);
	};

	const handleCancelEdit = () => {
		setFormData({
			year: car.year,
			make: car.make,
			model: car.model,
			mileage: car.mileage,
			engineCapacity: car.engineCap,
			price: car.price,
			exteriorColor: car.extColor,
			interiorColor: car.intColor,
			drivetrain: car.driveType,
			fuelType: car.fuel,
			transmission: car.transmission,
			stockType: car.stockType,
			description: car.description,
		});
		setEditing(false);
		setFormError(null);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleUpdate = async () => {
		try {
			const updatedCar = {
				year: formData.year,
				make: formData.make,
				model: formData.model,
				mileage: formData.mileage,
				engineCapacity: formData.engineCap,
				price: formData.price,
				exteriorColor: formData.extColor,
				interiorColor: formData.intColor,
				drivetrain: formData.driveType,
				fuelType: formData.fuel,
				transmission: formData.transmission,
				stockType: formData.stockType,
				description: formData.description,
			};
			await axios.put(`${API_URL}/api/vehicles/${id}`, updatedCar);
			const response = await axios.get(`${API_URL}/api/vehicles/${id}`);
			setCar(response.data);
			setEditing(false);
		} catch (error) {
			console.error('Error updating car:', error);
			setFormError('Failed to update car. Please try again.');
		}
	};

	if (error) {
		return <p>Error fetching car details: {error.message}</p>;
	}

	if (!car) {
		return <p>Loading...</p>;
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	return (
		<div className={classes.carDetail}>
			<h2>Car Details</h2>
			<div className={classes.images}>
				<Slider {...settings}>
					{car.images.map((image, index) => (
						<div
							key={index}
							className={classes.imageContainer}>
							<img
								src={`${API_URL}/images/cars/${image.imageName}`}
								alt={`${car.make} ${car.model}`}
								className={classes.image}
							/>
							<button
								onClick={() => handleDeleteImage(image.id)}
								className={classes.deleteButton}>
								X
							</button>
						</div>
					))}
				</Slider>
			</div>
			<div className={classes.details}>
				{editing ? (
					<div className={classes.editForm}>
						{formError && <p className={classes.error}>{formError}</p>}
						<form
							className={classes.form}
							onSubmit={(e) => e.preventDefault()}>
							<div className={classes.formGroup}>
								<label>Year:</label>
								<input
									type='text'
									name='year'
									value={formData.year}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Make:</label>
								<input
									type='text'
									name='make'
									value={formData.make}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Model:</label>
								<input
									type='text'
									name='model'
									value={formData.model}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Mileage:</label>
								<input
									type='text'
									name='mileage'
									value={formData.mileage}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Engine Capacity:</label>
								<input
									type='text'
									name='engineCapacity'
									value={formData.engineCap}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Price:</label>
								<input
									type='text'
									name='price'
									value={formData.price}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Exterior Color:</label>
								<input
									type='text'
									name='exteriorColor'
									value={formData.extColor}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Interior Color:</label>
								<input
									type='text'
									name='interiorColor'
									value={formData.intColor}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Drivetrain:</label>
								<input
									type='text'
									name='drivetrain'
									value={formData.driveType}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Fuel Type:</label>
								<input
									type='text'
									name='fuelType'
									value={formData.fuel}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Transmission:</label>
								<input
									type='text'
									name='transmission'
									value={formData.transmission}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Stock Type:</label>
								<input
									type='text'
									name='stockType'
									value={formData.stockType}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.formGroup}>
								<label>Description:</label>
								<textarea
									name='description'
									value={formData.description}
									onChange={handleInputChange}
								/>
							</div>
							<div className={classes.buttonGroup}>
								<button
									onClick={handleUpdate}
									className={classes.saveButton}>
									Save
								</button>
								<button
									onClick={handleCancelEdit}
									className={classes.cancelButton}>
									Cancel
								</button>
							</div>
						</form>
					</div>
				) : (
					<div>
						<p>
							<strong>Year:</strong> {car.year}
						</p>
						<p>
							<strong>Make:</strong> {car.make}
						</p>
						<p>
							<strong>Model:</strong> {car.model}
						</p>
						<p>
							<strong>Mileage:</strong> {car.mileage}
						</p>
						<p>
							<strong>Engine Capacity:</strong> {car.engineCap}
						</p>
						<p>
							<strong>Price:</strong> {car.price}
						</p>
						<p>
							<strong>Exterior Color:</strong> {car.extColor}
						</p>
						<p>
							<strong>Interior Color:</strong> {car.intColor}
						</p>
						<p>
							<strong>Drivetrain:</strong> {car.driveType}
						</p>
						<p>
							<strong>Fuel Type:</strong> {car.fuel}
						</p>
						<p>
							<strong>Transmission:</strong> {car.transmission}
						</p>
						<p>
							<strong>Stock Type:</strong> {car.stockType}
						</p>
						<p>
							<strong>Description:</strong> {car.description}
						</p>
						<button
							onClick={handleEdit}
							className={classes.editButton}>
							Edit
						</button>
					</div>
				)}
			</div>
		</div>
	);
}

export default CarDetail;
