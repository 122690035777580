import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import classes from './Signin.module.css';
import axios from 'axios';
import { AuthContext } from '../helpers/AuthContext';

const API_URL = `${window.location.protocol}//db.africagoldrefineries.com`;

function Signin() {
	const navigate = useNavigate();
	const { setAuthState } = useContext(AuthContext);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleError = (error) => {
		if (error.response) {
			return error.response.data.error;
		} else if (error.request) {
			return 'No response from server';
		} else {
			return 'An unexpected error occurred';
		}
	};

	const login = async (event) => {
		event.preventDefault();
		setLoading(true);
		const data = { email, password };

		try {
			const response = await axios.post(`${API_URL}/auth/login`, data, {
				withCredentials: true,
			});
			const newState = {
				name: response.data.name,
				id: response.data.id,
				status: true,
				role: response.data.role,
			};
			localStorage.setItem('token', response.data.token);
			setAuthState(newState);
			navigate('/dashboard/overview');
		} catch (error) {
			setMessage(handleError(error));
			setTimeout(() => setMessage(''), 10000);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			setAuthState({ status: true });
			navigate('/dashboard/overview');
		} else {
			setAuthState({ status: false });
		}
	}, [navigate, setAuthState]);

	return (
		<div className={classes.main}>
			<div className={classes.wrapper}>
				<div className={classes.container}>
					<h2>Sign in</h2>
					{message && <div className={classes.message}>{message}</div>}
					<form
						className={classes.form}
						onSubmit={login}>
						<div className={classes.formGroup}>
							<label htmlFor='email'>Email:</label>
							<input
								type='email'
								id='email'
								name='email'
								required
								value={email}
								onChange={(event) => setEmail(event.target.value)}
							/>
						</div>
						<div className={classes.formGroup}>
							<label htmlFor='password'>Password:</label>
							<div className={classes.passwordContainer}>
								<input
									type={showPassword ? 'text' : 'password'}
									id='password'
									name='password'
									required
									value={password}
									onChange={(event) => setPassword(event.target.value)}
								/>
								<button
									type='button'
									onClick={togglePasswordVisibility}
									className={classes.togglePasswordButton}
									aria-label={showPassword ? 'Hide password' : 'Show password'}>
									<FontAwesomeIcon
										className={classes.eye}
										icon={showPassword ? faEyeSlash : faEye}
									/>
								</button>
							</div>
						</div>
						<button
							type='submit'
							className={classes.signinButton}
							disabled={loading}>
							{loading ? 'Signing in...' : 'Sign In'}
						</button>
					</form>
					{/* <p className={classes.register}>
          Don't have an account? <Link to='/register'>Register here</Link>
        </p> */}
				</div>
			</div>
		</div>
	);
}

export default Signin;
