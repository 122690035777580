import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';

const RatingStars = ({ rating }) => {
	const maxStars = 5;
	const fullStars = Math.floor(rating);
	const halfStar = rating % 1 !== 0;
	const stars = [];

	// Add full stars
	for (let i = 0; i < fullStars && i < maxStars; i++) {
		stars.push(
			<FontAwesomeIcon
				key={i}
				icon={faStar}
			/>
		);
	}

	// Add half star if needed
	if (halfStar && stars.length < maxStars) {
		stars.push(
			<FontAwesomeIcon
				key='half'
				icon={faStarHalfAlt}
			/>
		);
	}

	// Add remaining empty stars
	const remainingStars = Math.max(maxStars - fullStars - (halfStar ? 1 : 0), 0);
	for (let i = 0; i < remainingStars; i++) {
		stars.push(
			<FontAwesomeIcon
				key={i + fullStars + (halfStar ? 1 : 0)}
				icon={faStar}
				style={{ color: '#e4e4e4' }}
			/>
		);
	}

	return <div>{stars}</div>;
};

export default RatingStars;
