import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import NewCar from './pages/shop/NewCar';
import UsedCar from './pages/shop/UsedCar';
import SellCar from './pages/SellCar';
import Signin from './dashboard/Signin';
import Register from './dashboard/Register';
import Dashboard from './dashboard/main/component/Dashboard';
import { AuthContextProvider } from './helpers/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Overview from './dashboard/main/component/Overview';
import Vehicles from './dashboard/main/component/Vehicles';
import Profile from './dashboard/main/component/Profile';
import AddVehicle from './dashboard/main/component/AddVehicle';
import Car from './pages/Car';
import Search from './pages/Search';
import Terms from './pages/Terms';
import Policy from './pages/Policy';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Contact from './pages/Contact';
import CarDetail from './dashboard/main/component/CarDetail';

function App() {
	return (
		<div className='App'>
			<AuthContextProvider>
				<Router basename='/'>
					<ScrollToTop />
					<Navbar />
					<Routes>
						<Route
							path='/'
							element={<Home />}
						/>
						<Route
							path='/shop/newcars'
							element={<NewCar />}
						/>
						<Route
							path='/shop/usedcars'
							element={<UsedCar />}
						/>
						<Route
							path='/sellyourcar'
							element={<SellCar />}
						/>
						<Route
							path='/car/:id'
							element={<Car />}
						/>
						<Route
							path='/signin'
							element={<Signin />}
						/>
						<Route
							path='/register'
							element={<Register />}
						/>
						<Route
							path='/contact'
							element={<Contact />}
						/>
						<Route
							path='/terms'
							element={<Terms />}
						/>
						<Route
							path='/policy'
							element={<Policy />}
						/>
						<Route
							path='/search'
							element={<Search />}
						/>
						<Route
							path='/dashboard/*'
							element={
								<ProtectedRoute
									element={Dashboard}
									role='user'
								/>
							}>
							<Route
								path='overview'
								element={<ProtectedRoute element={Overview} />}
							/>
							<Route
								path='vehicles'
								element={<ProtectedRoute element={Vehicles} />}
							/>
							<Route
								path='vehicles/:id'
								element={<ProtectedRoute element={CarDetail} />}
							/>
							<Route
								path='profile'
								element={<ProtectedRoute element={Profile} />}
							/>
							<Route
								path='vehicles/add'
								element={<ProtectedRoute element={AddVehicle} />}
							/>
						</Route>
					</Routes>
					<Footer />
				</Router>
			</AuthContextProvider>
		</div>
	);
}

export default App;
