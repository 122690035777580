import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import classes from './Navbar.module.css';
import logo from '../assets/logo.png';
import axios from 'axios';
import { AuthContext } from '../helpers/AuthContext';

const API_URL = `${window.location.protocol}//db.africagoldrefineries.com`;

function Navbar() {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const { authState, setAuthState } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchProfile = async () => {
			try {
				const response = await axios.get(`${API_URL}/auth/status`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				});
				const { user } = response.data;
				setAuthState((prevState) => ({
					...prevState,
					name: user.name,
					email: user.email,
					status: true,
				}));
			} catch (error) {
				console.error('Error fetching profile:', error);
			}
		};

		if (localStorage.getItem('token')) {
			fetchProfile();
		}
	}, [setAuthState]);

	const logout = () => {
		try {
			localStorage.removeItem('token');
			setAuthState({ name: '', id: '', status: false, role: '' });
			navigate('/signin');
		} catch (error) {
			console.error('Logout failed:', error);
		}
	};

	const isLoggedIn = authState?.status;

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	return (
		<nav className={classes.nav}>
			<div className={classes.wrapper}>
				<div className={classes.left}>
					<div className={classes.logo}>
						<Link
							to='/'
							className={classes.link}>
							<img
								src={logo}
								alt='logo'
								aria-label='Homepage'
							/>
						</Link>
					</div>
				</div>
				<div className={classes.right}>
					<div
						className={classes.menuIcon}
						onClick={toggleMobileMenu}>
						<FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
					</div>
					<div
						className={`${classes.floatMenu} ${
							isMobileMenuOpen ? classes.showMenu : ''
						}`}>
						<div className={classes.menu}>
							<Link
								to='/shop/newcars'
								className={classes.link}>
								<p>New cars</p>
							</Link>
							<Link
								to='/shop/usedcars'
								className={classes.link}>
								<p>Used cars</p>
							</Link>
							<Link
								to='/sellyourcar'
								className={classes.link}>
								<p>Sell your car</p>
							</Link>
							<Link
								to='/contact'
								className={classes.link}>
								<p>Get in touch</p>
							</Link>
						</div>
						<div
							className={`${classes.account} ${
								isMobileMenuOpen ? classes.showMenu : ''
							}`}>
							{isLoggedIn ? (
								<>
									<Link
										to='/dashboard/overview'
										className={classes.link}>
										<p>{authState.name} |</p>
									</Link>
									<Link
										onClick={logout}
										className={classes.link}
										aria-label='Logout'>
										<p>Logout</p>
									</Link>
								</>
							) : (
								<>
									<Link
										to='/signin'
										className={classes.link}>
										<p>Sign in</p>
									</Link>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default Navbar;
